import { useContext } from 'react';
import { ProfileContext }  from '../../App';
import { Route, NavLink, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TasksIcon, DocumentsIcon, TransactionsIcon, InspectionsIcon, ContractorsIcon, UnknownIcon, VerificationIcon } from '../icons/Icons'

function ClaimsNavbar(props) {
    const pageNotFound = "404";
    const {userProfileLossDraftsClaims, userProfileLossDraftsClaim, changeUserProfileLossDraftsClaim} = useContext(ProfileContext);
    const { t } = useTranslation(["Claim", "App"]);
    const claimsNavItems = [
        import.meta.env.VITE_CLAIMS_TAB_TASKS||"",
        import.meta.env.VITE_CLAIMS_TAB_DOCUMENTS||"",
        import.meta.env.VITE_CLAIMS_TAB_TRANSACTIONS||"",
        import.meta.env.VITE_CLAIMS_TAB_INSPECTIONS||"",
        import.meta.env.VITE_CLAIMS_TAB_CONTRACTORS||"",
        pageNotFound
    ];

    function renderBannerIcon(key, title) {
        //return <img src={`${process.env.PUBLIC_URL}/images/banner/claim-` + key + `.png`} />;
        if (key === import.meta.env.VITE_CLAIMS_TAB_TASKS) {
            return <TasksIcon class="banner-icon" />;
        }
        else if (key === import.meta.env.VITE_CLAIMS_TAB_DOCUMENTS) {
            return <DocumentsIcon class="banner-icon" />;
        }
        else if (key === import.meta.env.VITE_CLAIMS_TAB_TRANSACTIONS) {
            return <TransactionsIcon class="banner-icon" />;
        }
        else if (key === import.meta.env.VITE_CLAIMS_TAB_INSPECTIONS) {
            return <InspectionsIcon class="banner-icon" />;
        }
        else if (key === import.meta.env.VITE_CLAIMS_TAB_CONTRACTORS) {
            return <ContractorsIcon class="banner-icon" />;
        }
        else if (key === import.meta.env.VITE_CLAIMS_TAB_REGISTRATION) {
            return <VerificationIcon class="banner-icon" />;
        }
        else {
            return <UnknownIcon class="banner-icon" />;
        }
    }
    
    function assignNavItemClassName(index) {
        return (index === 0) ? "nav-item current" : "nav-item";
    }

    function renderNavItemDivider(index) {
        return ((index + 1) < claimsNavItems.length - 1) ? <>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</> : <></>;
    }

    function handleClaimChange(event) {
        event.preventDefault();
        changeUserProfileLossDraftsClaim(Number(event.target.value));
    };

    let contents;
    if (userProfileLossDraftsClaims.hasClaims) {
        let claimSelector;
        if (userProfileLossDraftsClaims.claims.length === 1) {
            claimSelector = <b>{userProfileLossDraftsClaim.claimNumber}</b>;
        }
        else {
            claimSelector = 
                <select id="claimselector" /*aria-label="claimselector"*/
                        defaultValue={userProfileLossDraftsClaim.claimId} 
                        onChange={handleClaimChange}
                >
                {
                    userProfileLossDraftsClaims.claims.map(item => {
                        return <option key={item.claimId} value={item.claimId}>{item.claimNumber}</option>
                    })
                }
                </select>
            ;
        }

        let items = 
            <div className="column-container space-between nav-container" >
                <div>
                    <label htmlFor="claimselector">{t('lblClaimNumber')}</label>
                    &nbsp;&nbsp;
                    {claimSelector}
                </div>
                <div className="column-container">
                {
                    claimsNavItems.map((item, index) => {
                        if (item === pageNotFound) { return null; }
                        const itemCapitalized = 'menu' + item.charAt(0).toUpperCase() + item.substring(1);
                        return <span key={item} id={item} className={assignNavItemClassName(index)}>
                            <NavLink to={item} className={({ isActive }) => "nav-item" + (isActive ? "-current" : "")}>{t(itemCapitalized)}</NavLink>
                            {renderNavItemDivider(index)}
                        </span>;
                    })
                }
                </div>
            </div>
        ;

        let pageTitle = 
            <div id="bannertitle" className="column-container flex-start" >
                <Routes>
                    <Route key="/" path="/" element={<></>} />
                    {
                        claimsNavItems.map((item, index) => {
                            const title = (item !== pageNotFound ? t('menu' + item.charAt(0).toUpperCase() + item.substring(1)) : t("App:lbl" + item));
                            const path = (item !== pageNotFound ? item : "*");
                            return <Route key={item} path={path}
                                element={
                                    <>
                                        {renderBannerIcon(item, title)}
                                        <h1 className="row-container center">{title}</h1>
                                    </>
                                }
                            />;
                        })
                    }
                </Routes>
            </div>
        ;

        contents = 
            <div className="row-container">
                { items }
                { pageTitle }
            </div>
        ;
    }
    else {
        const items = 
            <div id="navcontainer" className="column-container space-between nav-container">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
        ;

        const item = import.meta.env.VITE_CLAIMS_TAB_REGISTRATION||"";
        const title = t('menu' + item.charAt(0).toUpperCase() + item.substring(1));
        let pageTitle = 
            <div id="bannertitle" className="column-container flex-start" >
                <Routes>
                    <Route key={title} path="*"
                            element={
                                <>
                                    {renderBannerIcon(item, title)}
                                    <h1 className="row-container center">{title}</h1>
                                </>
                            }
                        />
                </Routes>
            </div>
        ;

        contents = 
            <div className="row-container">
                { items }
                { pageTitle }
            </div>
        ;
    }

    return (contents);
};

export default ClaimsNavbar;