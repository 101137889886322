import { useContext, useState } from 'react';
import { AuthContext }  from '../App';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { SuccessNotification } from './Notification';
import { PostResponse } from './Models';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { putForm } from '../util/HttpRequest';
import * as Yup from 'yup';

function PasswordUpdate(props) {
    const [saveErrorMsg, setSaveErrorMsg] = useState("");
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const { t } = useTranslation(["PasswordUpdate", "Form"]);

    const ValidationSchema = Yup.object().shape({
        currentPassword: Yup.string().required(t('lblCurrentPasswordRequired')),
        newPassword: Yup.string().required(t('lblNewPasswordRequired'))
    });

    const rules = (
        <>
        {   
            (
                props.passwordPolicy.minAgeDays > 0 ||
                props.passwordPolicy.excludesCommonlyUsed ||
                props.passwordPolicy.notSimilarToCurrent ||
                props.passwordPolicy.excludesProfileData || 
                props.passwordPolicy.maxRepeatedCharacters
            ) &&
            <>
                {t("lblPolicyExclude.title")}
                <ul>
                {
                    (props.passwordPolicy.minAgeDays > 0) &&
                    <li>{t("lblPolicyExclude.minAge", {count: props.passwordPolicy.minAgeDays * 24})}</li>
                }
                {   
                    props.passwordPolicy.excludesCommonlyUsed &&
                    <li>{t("lblPolicyExclude.excludesCommonlyUsed")}</li>
                }
                {   
                    props.passwordPolicy.notSimilarToCurrent &&
                    <li>{t("lblPolicyExclude.notSimilarToCurrent")}</li>
                }
                {   
                    props.passwordPolicy.excludesProfileData &&
                    <li>{t("lblPolicyExclude.excludesProfileData")}</li>
                }
                {   
                    props.passwordPolicy.maxRepeatedCharacters &&
                    <li>{t("lblPolicyExclude.maxRepeatedCharacters", {count: props.passwordPolicy.maxRepeatedCharacters})}</li>
                }
                </ul>
            </>
        }
        {   
            (
                props.passwordPolicy.length.min ||
                props.passwordPolicy.minUniqueCharacters || 
                props.passwordPolicy.minCharacters.special ||
                props.passwordPolicy.minCharacters.numeric ||
                props.passwordPolicy.minCharacters.uppercase ||
                props.passwordPolicy.minCharacters.lowercase
            ) &&
            <>
                {t("lblPolicyInclude.title")}
                <ul>
                {
                    props.passwordPolicy.length.min &&
                    <li>{t("lblPolicyInclude.minLength", {count: props.passwordPolicy.length.min})}</li>
                }
                {   
                    props.passwordPolicy.minUniqueCharacters &&
                    <li>{t("lblPolicyInclude.minUniqueCharacters", {count: props.passwordPolicy.minUniqueCharacters})}</li>
                }
                {   
                    props.passwordPolicy.minCharacters.special > 0 &&
                    <li>{t("lblPolicyInclude.minSpecialCharacters", {count: props.passwordPolicy.minCharacters.special})}</li>
                }
                {   
                    props.passwordPolicy.minCharacters.numeric > 0 &&
                    <li>{t("lblPolicyInclude.minNumericCharacters", {count: props.passwordPolicy.minCharacters.numeric})}</li>
                }
                {   
                    props.passwordPolicy.minCharacters.uppercase > 0 &&
                    <li>{t("lblPolicyInclude.minUppercaseCharacters", {count: props.passwordPolicy.minCharacters.uppercase})}</li>
                }
                {   
                    props.passwordPolicy.minCharacters.lowercase > 0 &&
                    <li>{t("lblPolicyInclude.minLowercaseCharacters", {count: props.passwordPolicy.minCharacters.lowercase})}</li>
                }
                </ul>
            </>
        }
        </>
    );

    function handleSubmit(values, setSubmitting) {
        setSubmitting(false);   // MUST invoke this before updating any parent props or the form submit happens twice!
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/Password");
        const formData = new FormData();
        formData.append("UserId", user.id);
        formData.append("CurrentPassword", values.currentPassword);
        formData.append("NewPassword", values.newPassword);
        console.log("Put User Password to " + url);

        putForm(url, accessToken, formData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                props.toggleUpdatePasswordModal(false);
                if (props.allowSuccessNotification) {
                    SuccessNotification(t('lblUpdateSuccessTitle'), t('lblUpdateSuccessBody'));
                }
            }
            else {
                setSaveErrorMsg(t('lblUpdateFailure'));
            }
        })
        .catch(() => {
            setSaveErrorMsg(t('lblUpdateFailure'));
        })
    };

    function renderForm() {
        return (
            <Formik
                initialValues ={{
                    currentPassword: "",
                    newPassword: ""
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting); 
                }}
            >
            {   
                ({ values, isSubmitting, handleBlur, setFieldValue, submitForm }) => (
                    <Form>
                        {rules}
                        <label htmlFor="currentPassword">{t('lblCurrentPassword')}</label>
                        <br />
                        <Field id="currentPassword" name="currentPassword" type="password" className="input width-input-large" placeholder={t('lblCurrentPassword')} />
                        <br />
                        <ErrorMessage name="currentPassword" component="div" className="error" />
                        <br />
                        <label htmlFor="newPassword">{t('lblNewPassword')}</label>
                        <br />
                        <Field id="newPassword" name="newPassword" type="password" className="input width-input-large" placeholder={t('lblNewPassword')} onBlur={handleBlur} />
                        <br />
                        <ErrorMessage name="newPassword" component="div" className="error" />
                        <br />
                        <Button type="default" className="button" onClick={() => props.toggleUpdatePasswordModal(false)}>{t('Form:btnCancel')}</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" className="button submit" disabled={isSubmitting} onClick={submitForm}>{t('title')}</Button>
                        <br /><br />
                        <div className="error">{saveErrorMsg}</div>
                    </Form>
                )
            }
            </Formik>
        );
    }
    
    return (
        <Modal open={true} 
               title={t('title')} 
               footer={null}
               onCancel={() => props.toggleUpdatePasswordModal(false)}
               centered={true}
               destroyOnClose={true}
        >
            <hr />
            {renderForm()}
        </Modal>
    );
}

export default PasswordUpdate;
