import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

function Loading (props) {
    const { t } = useTranslation('App');
    const size = props.size > '' ? props.size : 'default';
    const tip = (size === 'small') ? '' : props.tip > '' ? props.tip : t('lblLoading');

    return (
        <Spin size={size} tip={tip} delay={500} />
    );
}

export default Loading;