import {CacheKey}  from '../util/Cache';

export const ClearUserSession = async() => {
    for (const property in CacheKey.AUTH) {
      sessionStorage.removeItem(property.toLowerCase());
    }
    sessionStorage.removeItem(CacheKey.LOSS_DRAFTS_CLAIM_ID);
    sessionStorage.removeItem(CacheKey.LOSS_DRAFTS_CLAIMS);
}

export const ExecuteUserLogout = async(forceNoCache : boolean) => {
    ClearUserSession();

    // Set a CacheKey.AUTH.LOGOUT value here and reload page
    // During page load, if CacheKey.AUTH.LOGOUT ...
    // - is > 0, we know not to invoke auto login (and we decrement or delete CacheKey.AUTH.LOGOUT)
    // - is = 0, we know to invoke auto login
    //
    // Since React v18+ invokes useEffect twice during local development, CacheKey.AUTH.LOGOUT get
    // deleted by the first call and the second call ends up invoking auto login logic ... annoying!
    // So during local development, we set CacheKey.AUTH.LOGOUT=2 during logout. The first call
    // sees 2 and doesn't invoke auto login (and decrements 2 to 1). The second call sees 1 and 
    // doesn't invoke auto login (and deletes CacheKey.AUTH.LOGOUT)
    sessionStorage.setItem(CacheKey.AUTH.LOGOUT, (import.meta.env.MODE === "development-local" ? "2" : "1"));
    
    let nocache = forceNoCache ? ("?nocache=" + (new Date()).getTime()) : "";
    window.location.assign(import.meta.env.VITE_PINGONE_LOGOUT_REDIRECT_URI + nocache);
}
