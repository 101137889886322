import { useState, useEffect } from 'react';
import {get, postForm} from '../util/HttpRequest'
import {CacheKey, CacheTTL, TimedLocalCache} from '../util/Cache';

export class UserProfileLossDraftsClaim {
    claimId: number = 0;
    claimNumber: string = "";
};

export class UserProfileLossDraftsClaims {
    hasLoaded: boolean = false;
    claims: Array<UserProfileLossDraftsClaim> = [new UserProfileLossDraftsClaim()];
    get hasClaims() {
        return (this.hasLoaded && this.claims[0].claimId !== 0);
    }
}

class UserProfileLocal {
    email: string|undefined = "";
    mobilePhone: string|undefined = "";
    // sendSMS: boolean = false;
    // languageCode: string = "";
    // timeZone: string = "";
    // timeZoneOffset: number = 0.0;
    allowESignature: boolean = false;
    consentToESignature: boolean = false;
};

export class UserProfile {
    email: string|undefined = "";
    mobilePhone: string|undefined = "";
    // sendSMS: boolean = false;
    // languageCode: string = "";
    // timeZone: string = "";
    // timeZoneOffset: number = 0.0;
    allowESignature: boolean = false;
    consentToESignature: boolean = false;
    get hasLoaded() {
        return (this.email !== undefined && this.email > "");
    }
    public static copyFromUserProfileLocal(obj: UserProfileLocal) : UserProfile {
        const up = new UserProfile();
        up.email = obj.email;
        up.mobilePhone = obj.mobilePhone;
        // up.sendSMS = obj.sendSMS;
        // up.languageCode = obj.languageCode;
        // up.timeZone = obj.timeZone;
        // up.timeZoneOffset = obj.timeZoneOffset;
        up.allowESignature = obj.allowESignature;
        up.consentToESignature = obj.consentToESignature;
        return up;
    }
};

function useProfile(userId: string, accessToken: string) {
    const [queryParamsProcessed, setQueryParamsProcessed] = useState(false);
    const [userProfile, setUserProfile] = useState(new UserProfile());
    const [languagePreference, setLanguagePreference] = useState("en");
    const [userProfileLossDraftsClaim, setUserProfileLossDraftsClaim] = useState(new UserProfileLossDraftsClaim());
    const [userProfileLossDraftsClaims, setUserProfileLossDraftsClaims] = useState(new UserProfileLossDraftsClaims());
    const claimsRegEx = /^[Cc]\d{6,10}$/;

    function changeLanguagePreference(languageCode) {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/CultureCookie");
        const formData = new FormData();
        formData.append("languageCode", languageCode);
        console.log("Set Culture Cookie at " + url);

        postForm(url, accessToken, formData)
        .then(() => {
            setLanguagePreference(languageCode);
        });
    };
    
    function getUserProfile() {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/UserProfile");
        url.searchParams.set("UserId", userId);
        console.log("Fetch User Profile from " + url);
        
        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<UserProfileLocal>
        })
        .then( searchResults => {
            if (searchResults === null) {
                insertUserProfile();
            }
            else {
            	setUserProfile(UserProfile.copyFromUserProfileLocal(searchResults));
            }
        })
        .catch( error => {
            console.log("getUserProfile() => failed with error: " + error.toString());
            setUserProfile(new UserProfile());
        })
    };

    function insertUserProfile() {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/UserProfile");
        url.searchParams.set("UserId", userId);
        const formData = new FormData();
        formData.append("userId", userId);
        console.log("Insert User Profile at " + url);
        
        postForm(url, accessToken, formData)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<UserProfileLocal>
        })
        .then( searchResults => {
            setUserProfile(UserProfile.copyFromUserProfileLocal(searchResults));
        })
        .catch( error => {
            console.log("insertUserProfile() => failed with error: " + error.toString());
            setUserProfile(new UserProfile());
        })
    }

    function refreshUserProfile() {
        getUserProfile();
    }

    function fetchUserProfileLossDraftsClaims() {
        try {
            const savedValue = sessionStorage.getItem(CacheKey.LOSS_DRAFTS_CLAIMS);
            if (savedValue) {
                let upldc = new UserProfileLossDraftsClaims();
                upldc.hasLoaded = true;
                upldc.claims = JSON.parse(savedValue);
                setUserProfileLossDraftsClaims(upldc);
                console.log("Load Loss Draft Profile Claims from Session Storage");
            }
            else {
                throw new Error();
            }
        } 
        catch (e) {
            const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/UserClaims");
            url.searchParams.set("UserId", userId);
            console.log("Fetch Loss Draft Profile Claims from " + url);
            
            get(url, accessToken)
            .then((response) => {
                if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
                return response.json() as Promise<UserProfileLossDraftsClaim[]>;
            })
            .then(claims => {
                let upldc = new UserProfileLossDraftsClaims();
                upldc.hasLoaded = true;
                if (claims.length > 0) {
                    sessionStorage.setItem(CacheKey.LOSS_DRAFTS_CLAIMS, JSON.stringify(claims));
                    upldc.claims = claims;
                }
                setUserProfileLossDraftsClaims(upldc);
            })
            .catch(error => {
                console.log(error.message);
                console.log(error.stack);
            });
        }
    }

    function processQueryParams() {
        // if the URL path only has a valid code + clientId ...
        // save the clientId in storage and use the code to direct user to the correct page
        let currPath = window.location.pathname.substring(1).trim();
        if (claimsRegEx.test(currPath)) {
            localStorage.setItem(CacheKey.CLIENT_ID, currPath.substring(1));
            currPath = "claims";
        }
        else
        {
            // Grab URL params of interest
            const search = new URLSearchParams(window.location.search.toLowerCase());
            const claimId = Number(search.get("claimid")||sessionStorage.getItem(CacheKey.LOSS_DRAFTS_CLAIM_ID));
            const docuSignRequestId = Number(search.get("docusignrequestid"));
            const event = search.get("event");

            // Set the claimId in the state
            changeUserProfileLossDraftsClaim(claimId);

            // Grab recently completed DocuSign document's ID from URL and set it in local storage
            if (docuSignRequestId > 0 && event) {
                if (event === "signing_complete") {
                    let value = TimedLocalCache.getItem(CacheKey.COMPLETED_DOCUSIGN_REQUEST_ID);
                    if (value && value > "") {
                        value += ("," + docuSignRequestId.toString());
                    }
                    else {
                        value = docuSignRequestId.toString();
                    }
                    TimedLocalCache.setItem(CacheKey.COMPLETED_DOCUSIGN_REQUEST_ID, value, CacheTTL.COMPLETED_DOCUSIGN_REQUEST_ID);
                } 
                // else if (event === "signing_new") {
                //     setNewDocuSignRequestId(docuSignRequestId);
                // }
            }
        }
        
        // Remove all URL params
        window.history.replaceState({}, document.title, "/" + currPath);

        // Update state to finish processing this custom hook
        setQueryParamsProcessed(true);
    }

    function changeUserProfileLossDraftsClaim(claimId: number) {   
        if (!userProfileLossDraftsClaims.hasLoaded || !userProfileLossDraftsClaims.hasClaims) {
            return;
        }

        const index = userProfileLossDraftsClaims.claims.findIndex(x => x.claimId === claimId);
        if (index === -1) {
            sessionStorage.setItem(CacheKey.LOSS_DRAFTS_CLAIM_ID, userProfileLossDraftsClaims.claims[0].claimId.toString());
            setUserProfileLossDraftsClaim(userProfileLossDraftsClaims.claims[0]);
        } else {
            sessionStorage.setItem(CacheKey.LOSS_DRAFTS_CLAIM_ID, claimId.toString());
            setUserProfileLossDraftsClaim(userProfileLossDraftsClaims.claims[index]);
        }
    }

    useEffect(() => {
        if (userId === "" || accessToken === "") {
            return;
        }

        if (!userProfile.hasLoaded) {
            getUserProfile();
        }
        else if (!userProfileLossDraftsClaims.hasLoaded) {
            fetchUserProfileLossDraftsClaims();
        }
        else if (!queryParamsProcessed) {
            processQueryParams();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, accessToken, userProfile.hasLoaded, userProfileLossDraftsClaims.hasLoaded, queryParamsProcessed]);

    return {userProfile, refreshUserProfile, userProfileLossDraftsClaims, userProfileLossDraftsClaim, changeUserProfileLossDraftsClaim, queryParamsProcessed, languagePreference, changeLanguagePreference};
}

export default useProfile;