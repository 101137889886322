import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../App';
import { useTranslation } from 'react-i18next';
import { useTable, usePagination, useSortBy, useExpanded } from './Grid';
import {get} from '../util/HttpRequest'
import Loading from './Loading';
import GridSummary from './GridSummary';
import GridPagination from './GridPagination';
import { CaretDownIcon, CaretRightIcon } from './icons/Icons'

class FaqData {
    faqId: number = 0;
    displayOrder: number = 0;
    question: string = "";
    answer: string = "";
};

class FaqsData {
    recordCount: number = 0;
    refreshDate: Date = new Date();
    searchResults: FaqData[] = [];
};

function Faq(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [gridData, setGridData] = useState(new FaqsData());
    const {accessToken, extendSession} = useContext(AuthContext);
    const {languagePreference} = useContext(ProfileContext);
    const {t} = useTranslation(["Faq", "Language"]);
    
    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languagePreference]);

    function refreshData() {
        setLoading(true);
        getData();
    };

    function getData() {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/FAQs");
        console.log("Fetch FAQs from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<FaqData[]>
        })
        .then( searchResults => {
            const data = {
                recordCount: searchResults.length, 
                refreshDate: new Date(),
                searchResults: searchResults
            };
            setGridData(data);
            setErrorMsg("");
            setLoading(false);
        })
        .catch( error => {
            setGridData(new FaqsData());
            setErrorMsg(error.message);
            setLoading(false);
        })
    };

    function handleTableRowExpanderClick(row) {
        row.toggleRowExpanded();
    }

    function handleTableRowKeyDown (event: React.KeyboardEvent<HTMLTableRowElement>, row) {
        event.stopPropagation();
        switch (event.key) {
          case "Enter": 
            handleTableRowExpanderClick(row);
            break;  
          default: break;
        }
    };

    // A simple way to support a renderRowSubComponent is to make a render prop
    // This is NOT part of the React Table API, it's merely a rendering
    // option we are creating for ourselves in our table renderer
    function Table({ columns: userColumns, data, renderRowSubComponent }) {
        const pageSize = Number(import.meta.env.VITE_GRID_PAGE_SIZE);
        const {
            getTableProps,
            getTableBodyProps,
            //headerGroups,
            //rows,
            prepareRow,
            visibleColumns,
            page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
            canPreviousPage,
            canNextPage,
            // pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            //setPageSize,
            state: { pageIndex },
        } = useTable(
            {
                columns: userColumns,
                data,
                disableSortRemove: true,
                initialState: { 
                    pageIndex: 0,
                    pageSize: pageSize
                },
            },
            useSortBy,
            useExpanded, // We can useExpanded to track the expanded state for sub components too!
            usePagination
        )

        const pagination = <GridPagination canPreviousPage={canPreviousPage} canNextPage={canNextPage} 
            pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} />;
        
        const summary = <GridSummary pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} recordCount={data.length} 
            recordType={t('gridRecordType', {count: data.length})} refreshDate={gridData.refreshDate} pagination={pagination} />;
    
        return (
        <>
            {summary}
            <table className="table" role="presentation" {...getTableProps()}>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <React.Fragment key={{...row.getRowProps()}.key}>
                        <tr className={"row-" + (i % 2 === 0 ? "even" : "odd") + " pointer"} tabIndex={0} id={row.id} 
                            onKeyDown={(e) => handleTableRowKeyDown(e, row)} 
                            onClick={() => handleTableRowExpanderClick(row)}
                        >
                            {row.cells.map(cell => {
                            return (
                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            )
                            })}
                        </tr>
                        {/* If the row is in an expanded state, render a row with a column that fills the entire length of the table. */
                            row.isExpanded ? (
                                <tr className="row-detail" tabIndex={0}>
                                    <td colSpan={visibleColumns.length}>
                                        {renderRowSubComponent({ row })}
                                    </td>
                                </tr>
                            ) : null
                        }
                        </React.Fragment>
                    )
                })}
            </tbody>
            </table>
        </>
        )
    }

    const renderRowSubComponent = React.useCallback(
        ({ row }) => {
            return (
            <>
                {row.original.answer}
            </>
        )},
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    function renderDataGrid() {
        const columns = [{
            //Header: () => null,
            id: 'expander',
            sortable: false,
            Cell: ({ row, rows, toggleRowExpanded }) => (
                <div className="datagrid-data-left">
                    {
                        row.isExpanded && (
                            <CaretRightIcon title={t('rowCollapse')} />
                        )
                    }
                    {
                        !row.isExpanded && (
                            <CaretDownIcon title={t('rowExpand')} />
                        )
                    }
                </div>
            )
        }, {
            //Header: () => (<div className="datagrid-header-left">{t('columnAlert')}</div>),
            accessor: 'question',
            canSort: false
        }];

        let table = <Table
            columns={columns}
            data={gridData.searchResults} 
            renderRowSubComponent={renderRowSubComponent}
        />;

        return <div>{table}</div>;
    };

    let title = <h2>{t("title")}</h2>;
    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : loading ? <Loading size='large' /> : renderDataGrid();
    return <div>{title}<br />{contents}</div>;
};

export default Faq;
