import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { AuthContext }  from '../App';
import { useLocation } from 'react-router-dom';

function Home(props) {
    const { t } = useTranslation('Home');
    const {handleLogin} = useContext(AuthContext);
    const location = useLocation();
    
    return (
        <div>
            <h2>{t('title')}</h2>
            <h3>{t('lblNotSignedIn')}</h3>
            <Button title={t('btnLogin')} type="primary" className="button submit" onClick={() => handleLogin()}>{t('btnLogin')}</Button>
        </div>
    );
  }

export default Home;