import React, { useState, useContext } from 'react';
import { Button } from 'antd';
import { AuthContext }  from '../App';
import {get} from '../util/HttpRequest'

function Test(props) {
  const [ claimsApiTestResults, setClaimsApiTestResults ] = useState("");
  const {accessToken, extendSession, user} = useContext(AuthContext);

  async function handleClaimsApiTest() {
    const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/Test");
    console.log(url);
    
    get(url, accessToken)
    .then((response) => {
        if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
        return response.text();
    })
    .then(result => {
      setClaimsApiTestResults(result);
    })
    .catch(error => {
        console.log(error.message + " ||| " + error.stack);
        setClaimsApiTestResults(error.message + " ||| " + error.stack);
    });
  };
  
  let contents = 
    <div>
      <h2>Test Page</h2><br />
      <div>
        <h3>Configuration:</h3>
        <b>PingOne</b> - Environment ID = {import.meta.env.VITE_PINGONE_ENVIRONMENT_ID},  Client ID = {import.meta.env.VITE_PINGONE_CLIENT_ID}<br />
        <b>Redirect URIs</b> - Login = {import.meta.env.VITE_PINGONE_REDIRECT_URI}, Logout = {import.meta.env.VITE_PINGONE_LOGOUT_REDIRECT_URI}<br />
        <div>
          <Button type="primary" className="button submit" onClick={() => handleClaimsApiTest()}>Test Claims API</Button>
          &nbsp;{claimsApiTestResults}
        </div>
        <hr />
        <h3>User Session:</h3>
        <b>Id</b>: {user.id}<br />
        <b>Name</b>: {user.name}<br />
        <b>Email</b>: {user.email}<br />
        <b>Nickname</b>: {user.nickname}<br />
        <b>Token</b>: {accessToken}<br />
        <b>Token Inactivation Date</b>: {new Date(sessionStorage.getItem('token_inactive') || 0).toString()}<br />
        <b>Token Expiration Date</b>: {new Date(sessionStorage.getItem('token_expired') || 0).toString()}<br />
        <Button type="primary" className="button submit" onClick={() => extendSession()}>Extend User Session</Button>
      </div>
      <br />
    </div>
  ;
  
  return (contents);
}

export default Test;