import { useTable, usePagination, useSortBy, useExpanded } from 'react-table';
import { CaretDownIcon, CaretUpIcon, CaretUpDownIcon } from './icons/Icons';

export { useTable, usePagination, useSortBy, useExpanded };

export function handleTableColumnHeaderKeyDown (event: React.KeyboardEvent<HTMLTableCellElement>, column) {
    event.stopPropagation();
    switch (event.key) {
      case "Enter": 
        column.toggleSortBy();
        break;  
      default: break;
    }
};

function renderColumnHeaderContent (column, columnHeaderProps) {
    let icons = <></>;
    if (column.canSort) {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                icons = <CaretUpIcon class="column-header-icon" title={getTitle(column, columnHeaderProps)} />
            }
            else {
                icons = <CaretDownIcon class="column-header-icon" title={getTitle(column, columnHeaderProps)} />
            }
        }
        else {
            icons = <CaretUpDownIcon class="column-header-double-icon" title={getTitle(column, columnHeaderProps)} />;
        }
    }
    if (columnHeaderProps && columnHeaderProps.className) {
        return <><div className={columnHeaderProps.className}>{column.render('Header')} {icons}</div></>;
    } 
    else {
        return <>{column.render('Header')}{icons}</>;
    }
}

function getTitle (column, columnHeaderProps) {
    let title = "";
    if (column.canSort) {
        if (column.isSorted) {
            if (column.isSortedDesc) {
                title = columnHeaderProps.sortedDescLabel;
            }
            else {
                title = columnHeaderProps.sortedAscLabel;
            }
        }
        else {
            title = columnHeaderProps.unsortedLabel;
        }
    }
    return title;
}

export function renderColumnHeader (column, columnHeaderProps, sortedDescendingTitle=undefined, sortedAscendingTitle=undefined) {
    /* For accessibility reasons, columns with no header content need to be TD and not TH */
    if (columnHeaderProps) {
        return (
            <th {...column.getHeaderProps(column.getSortByToggleProps())} tabIndex={column.canSort ? 0 : -1} id={column.id} 
                    onKeyDown={(e) => handleTableColumnHeaderKeyDown(e, column)}
                    title={getTitle(column, columnHeaderProps)}
            >
                {renderColumnHeaderContent(column, columnHeaderProps)}
            </th>
        );
    }
    else {
        return (
            <td {...column.getHeaderProps(column.getSortByToggleProps())} tabIndex={column.canSort ? 0 : -1} id={column.id} 
                    onKeyDown={(e) => handleTableColumnHeaderKeyDown(e, column)}
            >
                {renderColumnHeaderContent(column, columnHeaderProps)}
            </td>
        );
    } 
}