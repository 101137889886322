import {User} from '../Auth/usePing';
import {get} from '../util/HttpRequest'

function updateUnreadAlertCountLabel(newCount:number, countElement:HTMLSpanElement|null) {
    if (countElement) {
        countElement.innerText = (newCount > 0 ? newCount.toString() : "");
    }
}

export function decrementUnreadAlertCountLabel() {
    const countElement = document.getElementById("unreadAlertCount");
    if (countElement) {
        let currentCount = Number(countElement.innerText);
        if (currentCount > 0) {
            updateUnreadAlertCountLabel(--currentCount, countElement);
        }
    }
}

export function setUnreadAlertCountLabel(newCount:number) {
    const countElement = document.getElementById("unreadAlertCount");
    updateUnreadAlertCountLabel(newCount, countElement);
}

export function setUnreadAlertCountLabelFromApi(user: User, accessToken: string) {
    const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/UnreadConsumerAlertCount");
    url.searchParams.set("UserId", user.id);
    console.log("Fetch UnreadConsumerAlertCount from " + url);
  
    get(url, accessToken)
    .then( response => {
        if (!response.ok) { throw response }
        return response.json() as Promise<number>
    })
    .then( searchResults => {
        setUnreadAlertCountLabel(searchResults);
    })
  };