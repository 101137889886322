// ============================================================================
// CacheKey & CacheTTL
// ============================================================================

export const CacheKey = {
    AUTH: {
        ACCESS_TOKEN: 'access_token',
        LOGOUT: 'logout',
        NONCE: 'nonce',
        STATE: 'state',
        TOKEN_INACTIVE: 'token_inactive',
        TOKEN_EXPIRED: 'token_expired',
    },
    CLIENT_ID: "client_id",
    COMPLETED_DOCUSIGN_REQUEST_ID: "completed_docusign_request_id",
    LANGUAGE_PREFERENCE: "language_preference",
    LOSS_DRAFTS_CLAIMS: "loss_drafts_claims",
    LOSS_DRAFTS_CLAIM_ID: "loss_drafts_claim_id",
    LOSS_DRAFTS_STAGING_CLAIM_SUBMITTED: "loss_drafts_staging_claim_submitted",
    SELECT_OPTIONS_COUNTRY: "select_options_country",
    SELECT_OPTIONS_US_STATE: "select_options_us_state",
    SERVER_OFFLINE: "server_offline",
    URL_PATHNAME: 'url_pathname',
    URL_SEARCH: 'url_search',
} as const;

export const CacheTTL = {
    // convert seconds to milliseconds
    COMPLETED_DOCUSIGN_REQUEST_ID: Number(import.meta.env.VITE_COMPLETED_DOCUSIGN_REQUEST_ID_TTL_SECONDS)*1000,
    INACTIVITY_TIMEOUT: Number(import.meta.env.VITE_INACTIVITY_TIMEOUT_SECONDS)*1000,
    SERVER_OFFLINE: Number(import.meta.env.VITE_SERVER_OFFLINE_TTL_SECONDS)*1000,
} as const;

// ============================================================================
// TimedLocalCache - timed localStorage
// ============================================================================

export class TimedLocalCache  {
    static setItem(key, value, ttl) {
        const item = {
            value: value,
            expiry: new Date().getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item));
    };

    static getItem(key) {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
    
        const item = JSON.parse(itemStr)
        if (new Date().getTime() > item.expiry) {
            localStorage.removeItem(key)
            return null
        }
        return item.value
    };
};