// i18next.ts
import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locales/en.json';
import es from './locales/es.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(initReactI18next)  // passed i18next down to react-i18next
  .init({
    debug: import.meta.env.MODE === 'development', 
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false  // react already escapes everything so this is safe
    },
    resources: {
      'en':en, 
      'es':es
    },
    returnNull: false
    // react: {
    //   wait: false,
    //   bindI18n: 'languageChanged loaded',
    //   bindStore: 'added removed',
    //   nsMode: 'default'
    // }
});

export default i18next;