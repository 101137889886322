import { useContext, useState } from 'react';
import { AuthContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import { Button, Collapse, Modal } from 'antd';
import { SelectFormField } from '../FormFields';
import { PostResponse } from '../Models';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { SuccessNotification } from '../Notification';
import { putJson } from '../../util/HttpRequest';
import {CacheKey}  from '../../util/Cache';

type FieldLength = {
    insuredName: number,
    claimNumber: number,
    loanNumber: number,
    mobilePhone: number,
    addressLine1: number,
    addressLine2: number,
    addressCity: number,
    addressStateCode: number,
    addressStateName: number,
    addressZipMin: number,
    addressZipMax: number,
    addressPostalCode: number
}

const { Panel } = Collapse;

function RegistrationUpdate(props) {
    const phoneNumberMaskRegExp = new RegExp(`[() _-]+`, 'g');
    const zipMaskRegExp = new RegExp(`[_-]+`, 'g');
    const [errorMsg, setErrorMsg] = useState("");
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const { t } = useTranslation(["ClaimRegistration", "Form", "Language"]);

    const fieldLength: FieldLength = {
        insuredName: 50,
        claimNumber: 50,
        loanNumber: 50,
        mobilePhone: 10,
        addressLine1: 50,
        addressLine2: 50,
        addressCity: 25,
        addressStateCode: 2,
        addressStateName: 25,
        addressZipMin: 5,
        addressZipMax: 9,
        addressPostalCode: 10
    };

    function displayStatesForUS(country: string) : boolean {
        return (country === "US");
    }

    const ValidationSchema = Yup.object().shape({
        claimNumber: Yup.string()
            .trim().required(t("lblClaimNumberRequired")),
        insuredName: Yup.string()
            .trim().required(t("lblInsuredNameRequired")),
        loanNumber: Yup.string()
            .trim().required(t("lblLoanNumberRequired")),
        mailingAddressSameAsPropertyAddress: Yup.boolean(),
        mailingAddress: Yup.object().when("mailingAddressSameAsPropertyAddress", {
            is: false,
            then: () => Yup.object().shape({
                countryISO: Yup.string().trim()
                    .required(t("lblAddressCountryRequired")),
                addressLine1: Yup.string().trim()
                    .required(t("lblAddressLine1Required")),
                city: Yup.string().trim()
                    .required(t("lblAddressCityRequired")),
                state: Yup.object().when("countryISO", {
                    is: (value) => (value === undefined || value === "" || value === "US"),
                    then: () => Yup.string().trim()
                        .required(t("lblAddressStateRequired")),
                    otherwise: () => Yup.string().trim()
                        .required(t("lblAddressStateProvinceRequired"))
                }),
                postalCode: Yup.object().when("countryISO", {
                    is: (value) => (value === undefined || value === "" || value === "US"),
                    then: () => Yup.string().trim()
                        .required(t("lblAddressZipRequired"))
                        .test("mailingAddressPostalCode-format", t("lblAddressZipInvalid"), (value, context) => {
                            return (!!value && (value.length === 5 || value.length === 9));
                        }),
                    otherwise: () => Yup.string().trim()
                        .required(t("lblAddressPostalCodeRequired"))
                })
             })
        }),
        mobilePhone: Yup.string()
            .required(t("lblMobilePhoneRequired"))
            .min(fieldLength.mobilePhone, t("lblMobilePhoneInvalid"))
            .max(fieldLength.mobilePhone, t("lblMobilePhoneInvalid")),
        propertyAddress: Yup.object().shape({
            addressLine1: Yup.string()
                .trim().required(t("lblAddressLine1Required")),
            city: Yup.string()
                .trim().required(t("lblAddressCityRequired")),
            state: Yup.string()
                .trim().required(t("lblAddressStateRequired")),
            postalCode: Yup.string()
                .trim().required(t("lblAddressZipRequired"))
                .test("propertyAddressPostalCode-format", t("lblAddressZipInvalid"), (value, context) => {
                    return (!!value && (value.length === 5 || value.length === 9));
                })
        })
    });

    function handleSubmit(values, setSubmitting) {
        setSubmitting(false);   // MUST invoke this before updating any parent props or the form submit happens twice!
        extendSession();

        // Copy form values into stagingClaim while removing properties we don't want to submit
        const {mailingAddressSameAsPropertyAddress, ...stagingClaim} = values;
        
        // Add props that were not on the form but are needed to submit
        stagingClaim.encodedClientId = props.stagingClaimData.encodedClientId;
        stagingClaim.lD_StagingClaimID = props.stagingClaimData.lD_StagingClaimID;
        stagingClaim.ssoUserId = props.stagingClaimData.ssoUserId;
        
        if (mailingAddressSameAsPropertyAddress) {
            stagingClaim.mailingAddress = stagingClaim.propertyAddress;
        }

        const jsonData = JSON.stringify(stagingClaim);
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/StagingClaim");

        console.log("Update User Registration at " + url);

        putJson(url, accessToken, jsonData)
        .then((response) => {
            if (!response.ok) { throw new Error(response.status + " - " + response.statusText)}
            return response.json() as Promise<PostResponse>;
        })
        .then(response => {
            if (response.success) {
                localStorage.removeItem(CacheKey.CLIENT_ID);
                localStorage.setItem(CacheKey.LOSS_DRAFTS_STAGING_CLAIM_SUBMITTED, "true");
                props.hideModal();
                props.refreshData();
                if (props.allowSuccessNotification) {
                    SuccessNotification(t('lblUpdateSuccessTitle'), t('lblUpdateSuccessBody'));
                }
            }
            else {
                setErrorMsg(t('lblUpdateFailure'));
            }
        })
        .catch(() => {
            setErrorMsg(t('lblUpdateFailure'));
        })
    }

    function renderForm() {
        return (
            <Formik
                initialValues ={{
                    claimNumber: props.stagingClaimData.claimNumber,
                    insuredName: props.stagingClaimData.insuredName,
                    loanNumber: props.stagingClaimData.loanNumber,
                    mailingAddress: props.stagingClaimData.mailingAddress,
                    mailingAddressSameAsPropertyAddress: props.stagingClaimData.mailingAddressSameAsPropertyAddress,
                    mobilePhone: props.stagingClaimData.mobilePhone,
                    propertyAddress: props.stagingClaimData.propertyAddress
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values, setSubmitting);
                }}
            >
            {
                ({ touched, values, isSubmitting, setFieldValue, submitForm }) => (
                    <Form>
                        <div className="column-container space-between">
                            <div>
                                <label htmlFor="insuredName">{t('lblInsuredName')}</label>
                                <br />
                                <Field id="insuredName" name="insuredName" type="text" className="input width-input-large" placeholder={t('lblInsuredName')} maxLength={fieldLength.insuredName} />
                                <br />
                                <ErrorMessage name="insuredName" component="div" className="error width-input-large" />
                                <br />
                            </div>
                            <div>
                                <label htmlFor="mobilePhone">{t('lblMobilePhone')}</label>
                                <br />
                                <Field name="mobilePhone">
                                {
                                    ({ field }) => {
                                        return <MaskedInput mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            {...field}
                                            id="mobilePhone"
                                            className="input width-input-large"
                                            placeholder={t('lblMobilePhone')}
                                            onChange={(event) => {
                                                setFieldValue(field.name, event.target.value.replace(phoneNumberMaskRegExp, ''));
                                            }} />
                                    }
                                }
                                </Field>
                                <br />
                                <ErrorMessage name="mobilePhone" component="div" className="error width-input-large" />
                                <br />
                            </div>
                        </div>
                        <div className="column-container space-between">
                            <div>
                                <label htmlFor="loanNumber">{t('lblLoanNumber')}</label>
                                <br />
                                <Field id="loanNumber" name="loanNumber" type="text" className="input width-input-large" placeholder={t('lblLoanNumber')} maxLength={fieldLength.loanNumber} />
                                <br />
                                <ErrorMessage name="loanNumber" component="div" className="error width-input-large" />
                                <br />
                            </div>
                            <div>
                                <label htmlFor="claimNumber">{t('lblClaimNumber')}</label>
                                <br />
                                <Field id="claimNumber" name="claimNumber" type="text" className="input width-input-large" placeholder={t('lblClaimNumber')} maxLength={fieldLength.claimNumber} />
                                <br />
                                <ErrorMessage name="claimNumber" component="div" className="error width-input-large" />
                                <br />
                            </div>
                        </div>
                        <Collapse accordion={true} defaultActiveKey={"p"} destroyInactivePanel={true}>
                            <Panel header={t('lblPropertyAddress')} key="p" showArrow={true}>
                                <div className="column-container space-between">
                                    <div>
                                        <label htmlFor="propertyAddress.addressLine1">{t('lblAddressLine1')}</label>
                                        <br />
                                        <Field id="propertyAddress.addressLine1" name="propertyAddress.addressLine1" type="text" className="input width-input-large" placeholder={t('lblAddressLine1')} maxLength={fieldLength.addressLine1} />
                                        <br />
                                        <ErrorMessage name="propertyAddress.addressLine1" component="div" className="error width-input-large" />
                                        <br />
                                    </div>
                                    <div>
                                        <label htmlFor="propertyAddress.addressLine2">{t('lblAddressLine2')}</label>
                                        <br />
                                        <Field id="propertyAddress.addressLine2" name="propertyAddress.addressLine2" type="text" className="input width-input-large" placeholder={t('lblAddressLine2')} maxLength={fieldLength.addressLine2} />
                                        <br />
                                        <ErrorMessage name="propertyAddress.addressLine2" component="div" className="error width-input-large" />
                                        <br />
                                    </div>
                                    <div>
                                        <label htmlFor="propertyAddress.city">{t('lblAddressCity')}</label>
                                        <br />
                                        <Field id="propertyAddress.city" name="propertyAddress.city" type="text" className="input width-input-large" placeholder={t('lblAddressCity')} maxLength={fieldLength.addressCity} />
                                        <br />
                                        <ErrorMessage name="propertyAddress.city" component="div" className="error width-input-large" />
                                        <br />
                                    </div>
                                    <div>
                                        <label id="propertyAddressStateLabel">{t('lblAddressState')}</label>
                                        <br />
                                        <div className='width-input-large'>
                                            <Field name="propertyAddress.state" ariaLabelledBy="propertyAddressStateLabel" component={SelectFormField} options={props.usStateSelectOptions.options} />
                                        </div>
                                        <ErrorMessage name="propertyAddress.state" component="div" className="error width-input-large" />
                                        <br />
                                    </div>
                                    <div>
                                        <label htmlFor="propertyAddress.postalCode">{t('lblAddressZip')}</label>
                                        <br />
                                        <Field name="propertyAddress.postalCode">
                                        {
                                            ({ field }) => {
                                            return <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                {...field}
                                                id="propertyAddress.postalCode"
                                                className="input width-input-large"
                                                placeholder={t('lblAddressZip')} 
                                                onChange={(event) => {
                                                    setFieldValue(field.name, event.target.value.replace(zipMaskRegExp, ''));
                                                }} />
                                            }
                                        }
                                        </Field>
                                        <br />
                                        <ErrorMessage name="propertyAddress.postalCode" component="div" className="error width-input-large" />
                                        <br />
                                    </div>
                                </div>
                            </Panel>
                            <Panel header="Mailing Address" key="m" showArrow={true}>
                                <div>
                                    <label htmlFor="mailingAddressSameAsPropertyAddress">
                                        <Field id="mailingAddressSameAsPropertyAddress" name="mailingAddressSameAsPropertyAddress" type="checkbox" />
                                        &nbsp;{t('lblMailingAddressSameAsPropertyAddress')}
                                    </label>
                                    <br /><br />
                                    {
                                        !values.mailingAddressSameAsPropertyAddress && (
                                            <div className="column-container space-between">
                                            <div>
                                                <label htmlFor="mailingAddress.addressLine1">{t('lblAddressLine1')}</label>
                                                <br />
                                                <Field id="mailingAddress.addressLine1" name="mailingAddress.addressLine1" type="text" className="input width-input-large" placeholder={t('lblAddressLine1')} maxLength={fieldLength.addressLine1} />
                                                <br />
                                                <ErrorMessage name="mailingAddress.addressLine1" component="div" className="error width-input-large" />
                                                <br />
                                            </div>
                                            <div>
                                                <label htmlFor="mailingAddress.addressLine2">{t('lblAddressLine2')}</label>
                                                <br />
                                                <Field id="mailingAddress.addressLine2" name="mailingAddress.addressLine2" type="text" className="input width-input-large" placeholder={t('lblAddressLine2')} maxLength={fieldLength.addressLine2} />
                                                <br />
                                                <ErrorMessage name="mailingAddress.addressLine2" component="div" className="error width-input-large" />
                                                <br />
                                            </div>
                                            <div>
                                                <label htmlFor="mailingAddress.city">{t('lblAddressCity')}</label>
                                                <br />
                                                <Field id="mailingAddress.city" name="mailingAddress.city" type="text" className="input width-input-large" placeholder={t('lblAddressCity')} maxLength={fieldLength.addressCity} />
                                                <br />
                                                <ErrorMessage name="mailingAddress.city" component="div" className="error width-input-large" />
                                                <br />
                                            </div>
                                            {
                                                displayStatesForUS(values.mailingAddress.countryISO) && 
                                                (
                                                    <>
                                                    <div>
                                                        <label id="mailingAddressStateLabel">{t('lblAddressState')}</label>
                                                        <br />
                                                        <div className='width-input-large'>
                                                            <Field name="mailingAddress.state" ariaLabelledBy="lblAddressState" component={SelectFormField} options={props.usStateSelectOptions.options} />
                                                        </div>
                                                        <ErrorMessage name="mailingAddress.state" component="div" className="error width-input-large" />
                                                        <br />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="mailingAddress.postalCode">{t('lblAddressZip')}</label>
                                                        <br />
                                                        <Field name="mailingAddress.postalCode">
                                                        {
                                                            ({ field }) => {
                                                            return <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                                                {...field}
                                                                id="mailingAddress.postalCode"
                                                                className="input width-input-large"
                                                                placeholder={t('lblAddressZip')} 
                                                                onChange={(event) => {
                                                                    setFieldValue(field.name, event.target.value.replace(zipMaskRegExp, ''));
                                                                }} />
                                                            }
                                                        }
                                                        </Field>
                                                        <br />
                                                        <ErrorMessage name="mailingAddress.postalCode" component="div" className="error width-input-large" />
                                                        <br />
                                                    </div>
                                                    </>
                                                )
                                            }
                                            {
                                                !displayStatesForUS(values.mailingAddress.countryISO) && 
                                                (
                                                    <>
                                                    <div>
                                                        <label htmlFor="mailingAddress.state">{t('lblAddressStateProvince')}</label>
                                                        <br />
                                                        <Field name="mailingAddress.state">
                                                        {
                                                            ({ field }) => {
                                                            return <MaskedInput mask={[/[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/, /[A-Za-z0-9 ,-]/]}
                                                                {...field}
                                                                id="mailingAddress.state"
                                                                className="input width-input-large"
                                                                guide={false}
                                                                showMask={false}
                                                                placeholder={t('lblAddressStateProvince')} 
                                                                onChange={(event) => {
                                                                    setFieldValue(field.name, event.target.value);
                                                                }} />
                                                            }
                                                        }
                                                        </Field>
                                                        <br />
                                                        <ErrorMessage name="mailingAddress.state" component="div" className="error width-input-large" />
                                                        <br />
                                                    </div>
                                                    <div>
                                                        <label htmlFor="mailingAddress.postalCode">{t('lblAddressPostalCode')}</label>
                                                        <br />
                                                        <Field name="mailingAddress.postalCode">
                                                        {
                                                            ({ field }) => {
                                                                return <MaskedInput mask={[/[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/, /[A-Za-z0-9 -]/]}
                                                                {...field}
                                                                id="mailingAddress.postalCode"
                                                                className="input width-input-large"
                                                                guide={false}
                                                                showMask={false}
                                                                placeholder={t('lblAddressPostalCode')} 
                                                                onChange={(event) => {
                                                                    setFieldValue(field.name, event.target.value);
                                                                }} />
                                                            }
                                                        }
                                                        </Field>
                                                        <br />
                                                        <ErrorMessage name="mailingAddress.postalCode" component="div" className="error width-input-large" />
                                                        <br />
                                                    </div>
                                                    </>
                                                )
                                            }
                                            <div>
                                                <label id="mailingAddressCountryLabel">{t('lblAddressCountry')}</label>
                                                <br />
                                                <div className='width-input-large'>
                                                    <Field name="mailingAddress.countryISO" ariaLabelledBy="mailingAddressCountryLabel" component={SelectFormField} options={props.countrySelectOptions.options} />
                                                </div>
                                                <ErrorMessage name="mailingAddress.countryISO" component="div" className="error width-input-large" />
                                                <br />
                                            </div>
                                        </div>
                                        )
                                    }
                                </div>
                            </Panel>
                        </Collapse>

                        <br /><br />

                        <Button type="default" className="button" onClick={() => props.hideModal()}>{t('Form:btnCancel')}</Button>
                        &nbsp;&nbsp;
                        <Button type="primary" className="button submit" disabled={isSubmitting} onClick={submitForm}>{t('lblUpdate')}</Button>
                        <br /><br />
                        <div className="error">{errorMsg}</div>
                    </Form>
                )
            }
            </Formik>
        );
    }

    return (
        <Modal open={true} 
               title={t('lblUpdate')} 
               footer={null}
               onCancel={() => props.hideModal()}
               centered={true}
               destroyOnClose={true}
        >
            <hr />
            {renderForm()}
        </Modal>
    );
}

export default RegistrationUpdate;
