import React, { useContext, useEffect, useState } from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { useTranslation } from 'react-i18next';
import { renderColumnHeader, useTable, usePagination, useSortBy, useExpanded } from '../Grid';
import Loading from '../Loading';
import GridSummary from '../GridSummary';
import GridPagination from '../GridPagination';
import {formatAddressOneLine, formatCompanyContact, formatPhone} from '../../util/Format';
import {get} from '../../util/HttpRequest'

class ClaimContractorData {
    companyName: string = "";
    contactName: string = "";
    internationalCode: string = "";
    areaCode: string = "";
    phoneNumber: string = "";
    extension: string = "";
    addressLine1: string = "";
    addressLine2: string = "";
    city: string = "";
    state: string = "";
    postalCode: string = "";
    countryISO: string = "";
};

class ClaimContractorsData {
    recordCount: number = 0;
    refreshDate: Date = new Date();
    searchResults: ClaimContractorData[] = [];
};

function ClaimContractors(props) {
    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState("");
    const [gridData, setGridData] = useState(new ClaimContractorsData());
    const {accessToken, extendSession, user} = useContext(AuthContext);
    const {userProfileLossDraftsClaim} = useContext(ProfileContext);
    const { t } = useTranslation(["ClaimContractors", "DataGrid"]);
    const columnHeaderProps = [
        {
            id: 'company', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'address', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        },
        {
            id: 'phone', className: 'datagrid-header-left', sortedDescLabel: t('DataGrid:lblSortedDescending'), sortedAscLabel: t('DataGrid:lblSortedAscending'), unsortedLabel: t('DataGrid:lblUnsorted')
        }
    ];

    useEffect (() => {
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileLossDraftsClaim.claimId]);

    function refreshData() {
        setLoading(true);
        getData();
    };

    function getData() {
        extendSession();
        
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/ClaimContractors");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        console.log("Fetch Claim Contractors from " + url);

        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<ClaimContractorData[]>
        })
        .then( searchResults => {
            const data = {
              recordCount: searchResults.length, 
              refreshDate: new Date(),
              searchResults: searchResults
            };
            setGridData(data);
            setErrorMsg("");
            setLoading(false);
        })
        .catch( error => {
            setGridData(new ClaimContractorsData());
            setErrorMsg(error.message);
            setLoading(false);
        })
    };

    function Table({ columns, data }) {
        const pageSize = Number(import.meta.env.VITE_GRID_PAGE_SIZE);
        // Use the state and functions returned from useTable to build your UI
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            //rows,
            prepareRow,
            //visibleColumns,
            page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
            canPreviousPage,
            canNextPage,
            // pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            //setPageSize,
            state: { pageIndex/*, pageSize, expanded*/ },
        } = useTable({
            columns,
            data,
            disableSortRemove: true,
            initialState: { 
                pageIndex: 0,
                pageSize: pageSize
                },
            },
            useSortBy,
            useExpanded, // We can useExpanded to track the expanded state for sub components too!
            usePagination
        )

        const filters = <></>;

        const pagination = <GridPagination canPreviousPage={canPreviousPage} canNextPage={canNextPage} 
            pageIndex={pageIndex} pageCount={pageCount} gotoPage={gotoPage} previousPage={previousPage}
            nextPage={nextPage} />;
        
        const summary = <GridSummary pageIndex={pageIndex} pageCount={pageCount} pageSize={pageSize} recordCount={data.length} 
            recordType={t('gridRecordType', {count: data.length})} refreshDate={gridData.refreshDate} pagination={pagination} />;

        return (
        <>
          {filters}
          <br />
          {summary}
          <table className="table" role="presentation" {...getTableProps()}>
              <thead>
                  {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        renderColumnHeader(column, columnHeaderProps.find(x => x.id === column.id))
                      ))}
                  </tr>
                  ))}
              </thead>
              <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
              prepareRow(row)              
              return (
                  <React.Fragment key={{...row.getRowProps()}.key}>
                    <tr className={"row-" + (i % 2 === 0 ? "even" : "odd")}>
                        {row.cells.map(cell => {
                        return (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        )
                        })}
                    </tr>
                  </React.Fragment>
              )
              })}
          </tbody>
          </table>
        </>
      )
  }

    function renderDataGrid() {
        const columns = [{
            Header: () => (<>{t('columnCompany')}</>),
            accessor: (row) => {
                return formatCompanyContact(row.companyName, row.contactName);
            },
            id: columnHeaderProps.find(x => x.id === 'company')?.id,
            canSort: true,
        }, {
            Header: () => (<>{t('columnAddress')}</>),
            accessor: (row) => {
                return formatAddressOneLine([row.addressLine1, row.addressLine2, row.city, row.state, row.postalCode, row.countryISO]);
            },
            id: columnHeaderProps.find(x => x.id === 'address')?.id,
            canSort: true,
        },  {
            Header: () => (<span className="datagrid-header-left">{t('columnPhone')}</span>),
            accessor: (row) => {
                return formatPhone(row.internationalCode, row.areaCode, row.phoneNumber, row.extension);
            },
            id: columnHeaderProps.find(x => x.id === 'phone')?.id,
            canSort: true,
        }];

        let table = <Table
            columns={columns}
            data={gridData.searchResults} 
        />;

        return <div>{table}</div>;
    };

    let title = <h2>{t("title")}</h2>;
    let contents = (errorMsg > '') ? <p><em>{errorMsg}</em></p> : loading ? <Loading size='large' /> : renderDataGrid();
    return <div>{title}<br />{contents}</div>
};

export default ClaimContractors;