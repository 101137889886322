import { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthContext, ProfileContext }  from '../App';
import { Outlet } from 'react-router-dom';
//import Language from './Language';
import SiteBanner from './SiteBanner';
import ClaimsNavbar from './Claim/ClaimsNavbar';
import OtherNavbar from './OtherNavbar';
import Footer from './Footer';

function Layout(props)  {
    const {isAuthenticated} = useContext(AuthContext);
    const {userProfileLossDraftsClaims} = useContext(ProfileContext);

    let contents = 
        <div className="row-container">
            <div id="titlebar" className="column-container space-between">
                {/* <span>{process.env.npm_package_name} v{process.env.npm_package_version}</span> */}
                <span>{import.meta.env.VITE_SITE_NAME} v{import.meta.env.VITE_SITE_VERSION}</span>
                {/* {
                    isAuthenticated && <Language />
                } */}
            </div>
            <div id="top">
            {
                isAuthenticated && (
                <>
                    <div className="column-container flex-end">
                        <SiteBanner />
                    </div>
                    <Routes>
                        {
                            userProfileLossDraftsClaims.hasClaims && (
                                <Route path="claims" element={
                                    <Navigate to="tasks" replace={true} />
                                } />
                            )
                        }
                        <Route path="claims/*" element={<ClaimsNavbar />} />
                        <Route path="*" element={<OtherNavbar />} />
                    </Routes>
                </>)
            }
            </div>
            <div id="content" className="row-container">
                <Outlet />
            </div>
            <div>
                <Footer />
            </div>
        </div>;
    return (contents);
}

export default Layout;