import {CacheKey, CacheTTL, TimedLocalCache} from '../util/Cache';
import {ExecuteUserLogout } from '../Auth/UserSession';

function request(url: URL, accessToken: string, method: string, formData: FormData|null, jsonData: string|null) {
    // validate
    if (['post', 'put'].includes(method)) {
        if (formData === null && jsonData === null) {
            throw new Error("400 - Bad Request (formData or jsonData required)");
        }
        else if (formData !== null && jsonData !== null) {
            throw new Error("400 - Bad Request (formData and jsonData cannot co-exist)");
        }
    }
    else if (formData !== null || jsonData !== null) {
        throw new Error("400 - Bad Request (formData and jsonData not allowed)");
    }
    // make request
    return fetch(url.toString(), {
        method: method,
        mode: 'cors',
        credentials: 'include',
        headers: {
          authorization: "Bearer " + accessToken,
          ...(jsonData !== null) && {"Content-Type": "application/json"}
        },
        body: (formData !== null ? formData : jsonData) // formData > jsonData > null
    })
    .catch( error => {       
        if (error !== null && error.message === "Failed to fetch") {
            // if we have "Failed to fetch" X times in a short period of time, log out the user because server is probably down
            let value = (Number(TimedLocalCache.getItem(CacheKey.SERVER_OFFLINE)) || 0) + 1;
            if (value >= Number(import.meta.env.VITE_SERVER_OFFLINE_ATTEMPTS)) {
                TimedLocalCache.setItem(CacheKey.SERVER_OFFLINE, 0, CacheTTL.SERVER_OFFLINE);
                ExecuteUserLogout(true);
            }
            else {
                TimedLocalCache.setItem(CacheKey.SERVER_OFFLINE, value, CacheTTL.SERVER_OFFLINE);
            }
        }
        throw error;
    })
    ;
}

export function get(url: URL, accessToken: string) {
    return request(url, accessToken, 'get', null, null);
}

export function postForm(url: URL, accessToken: string, formData: FormData) {
    return request(url, accessToken, 'post', formData, null);
}

export function postJson(url: URL, accessToken: string, jsonData: string) {
    return request(url, accessToken, 'post', null, jsonData);
}

export function putForm(url: URL, accessToken: string, formData: FormData) {
    return request(url, accessToken, 'put', formData, null);
}

export function putJson(url: URL, accessToken: string, jsonData: string) {
    return request(url, accessToken, 'put', null, jsonData);
}

export function deleete(url: URL, accessToken: string) {
    return request(url, accessToken, 'delete', null, null);
}