import React, { Component } from 'react';
import { postForm } from '../util/HttpRequest';
import { withTranslation } from 'react-i18next';

interface IErrorBoundaryState {
    hasError: boolean;
    exceptionLogged: boolean;
};

class ErrorBoundary extends Component <any, IErrorBoundaryState> {
    constructor(props) {
      super(props);
      this.state = { hasError: false, exceptionLogged: false };
    }
  
    // There is no hook-friendly alternative for this lifecycle method yet so ErrorBoundary must remain a class
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    // There is no hook-friendly alternative for this lifecycle method yet so ErrorBoundary must remain a class
    componentDidCatch(error, info) {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/Log");
        console.log("Log error to " + url);

        // Log the error to the logging API.
        // Level can be Fatal, Error, Warning, Information, Debug, Verbose.
        // In order to make error logs more searchable (e.g. structured logging), we can have placeholders 
        // in the Message field like "This is a {ph1} message with {ph2} placeholders", and then adding the
        // values of all placeholders into a string array called Values.

        let values = [""];  // string array where each item is a placeholder value from the Message
        const formData = new FormData();
        formData.append("User", this.props.user.id);
        formData.append("Level", "Error");
        formData.append("Message", error.message);
        formData.append("Exception", error.stack);
        values.forEach(x => formData.append("Values", x));
        
        postForm(url, this.props.accessToken, formData)
        .then((response) => {
            this.setState({ exceptionLogged: response.ok });
        })
    }

    

    render() {
      if (this.state.hasError) {
        return <div>
          <h2>{this.props.t('title')}</h2>
          <h3>{this.props.t('lblError', {contactInfo: import.meta.env.VITE_ERROR_CONTACT_INFO})}</h3>
        </div>;
      }
      return this.props.children;
    }
  }

  export default withTranslation('ErrorBoundary')(ErrorBoundary);