import { 
  BellOutlined, BugOutlined, CaretDownOutlined, CaretLeftOutlined, CaretRightOutlined, CaretUpOutlined, 
  CheckCircleOutlined, CopyOutlined, DeleteOutlined, DollarCircleOutlined, DoubleLeftOutlined, 
  DoubleRightOutlined, DownCircleOutlined, EditOutlined, ExclamationCircleOutlined, EyeOutlined, 
  FileOutlined, FilePdfOutlined, FormOutlined, GlobalOutlined, HomeOutlined, InfoCircleOutlined, 
  LeftOutlined, LogoutOutlined, MailOutlined, MenuOutlined, MessageOutlined, MinusOutlined, OrderedListOutlined, 
  PhoneOutlined, PlusOutlined, QuestionOutlined, QuestionCircleOutlined, RetweetOutlined, RightOutlined, SafetyCertificateOutlined, ToolOutlined, 
  UpCircleOutlined, UploadOutlined, UserOutlined
} from '@ant-design/icons';

function getClassName(className: string) {
  return (className > "" ? className : "medium-icon");
}

export function AlertIcon(props) {
  return (<BellOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DebugIcon(props) {
  return (<BugOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretDownIcon(props) {
  return (<CaretDownOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretLeftIcon(props) {
  return (<CaretLeftOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretRightIcon(props) {
  return (<CaretRightOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretUpIcon(props) {
  return (<CaretUpOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CaretUpDownIcon(props) {
  return (
    <span className={getClassName(props.class)} aria-label={props.title} title={props.title}>
      <CaretUpOutlined aria-label={props.title} /><CaretDownOutlined aria-label={props.title} />
    </span>
  );
}

export function CheckIcon(props) {
  return (<CheckCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CollapseIcon(props) {
  return (<DownCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function CommunicationIcon(props) {
  return (<MailOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ContractorsIcon(props) {
  return (<ToolOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DeleteIcon(props) {
  return (<DeleteOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DocumentsIcon(props) {
  return (<CopyOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DoubleLeftIcon(props) {
  return (<DoubleLeftOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function DoubleRightIcon(props) {
  return (<DoubleRightOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function EditIcon(props) {
  return (<FormOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ExclamationIcon(props) {
  return (<ExclamationCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ExpandIcon(props) {
  return (<UpCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function FileIcon(props) {
  return (<FileOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function FilePdfIcon(props) {
  return (<FilePdfOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function GlobeIcon(props) {
  return (<GlobalOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);  
}

export function HomeIcon(props) {
  return (<HomeOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function InfoIcon(props) {
  return (<InfoCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function InspectionsIcon(props) {
  return (<EyeOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function LeftIcon(props) {
  return (<LeftOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function LogoutIcon(props) {
  return (<LogoutOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MenuIcon(props) {
  return (<MenuOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MessageIcon(props) {
  return (<MessageOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function MinusIcon(props) {
  return (<MinusOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function PhoneIcon(props) {
  return (<PhoneOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function PlusIcon(props) {
  return (<PlusOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ProfileIcon(props) {
  return (<UserOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function QuestionIcon(props) {
  return (<QuestionCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function ResetIcon(props) {
  return (<RetweetOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function RightIcon(props) {
  return (<RightOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function SignatureIcon(props) {
  return (<EditOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function TasksIcon(props) {
  return (<OrderedListOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function TransactionsIcon(props) {
  return (<DollarCircleOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function UnknownIcon(props) {
  return (<QuestionOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function UploadIcon(props) {
  return (<UploadOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}

export function VerificationIcon(props) {
  return (<SafetyCertificateOutlined className={getClassName(props.class)} aria-label={props.title} title={props.title} />);
}