import React, {useContext, useEffect, useState} from 'react';
import { AuthContext, ProfileContext }  from '../../App';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import {get} from '../../util/HttpRequest'
import ErrorBoundary from '../ErrorBoundary';
import { SelectOption, SelectOptions } from '../Models';
import ClaimContractors from './Contractors';
import ClaimDetails from './Details';
import ClaimInspections from './Inspections';
import ClaimTasks from './Tasks';
import ClaimTransactions from './Transactions';
import ClaimDocuments from './Documents';
import ClaimRegistration from './Registration';
import Loading from '../Loading';
import { useTranslation } from 'react-i18next';

function Claims(props) {
    const [documentTypeSelectOptions, setDocumentTypeSelectOptions] = useState(new SelectOptions());
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [isClaimActive, setIsClaimActive] = useState(false);
    const [documentUploadModalShow, setDocumentUploadModalShow] = useState(false);
    const {accessToken, user} = useContext(AuthContext);
    const {userProfileLossDraftsClaims, userProfileLossDraftsClaim} = useContext(ProfileContext);
    const { t } = useTranslation(["Claim", "App"]);
    const validClaimsPaths = [
        "/claims/",
        "/claims/" + import.meta.env.VITE_CLAIMS_TAB_TASKS,
        "/claims/" + import.meta.env.VITE_CLAIMS_TAB_DOCUMENTS,
        "/claims/" + import.meta.env.VITE_CLAIMS_TAB_TRANSACTIONS,
        "/claims/" + import.meta.env.VITE_CLAIMS_TAB_INSPECTIONS,
        "/claims/" + import.meta.env.VITE_CLAIMS_TAB_CONTRACTORS
    ];
    const location = useLocation();

    useEffect (() => {
        if (userProfileLossDraftsClaims.hasClaims) {
            loadDocumentTypeSelectOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfileLossDraftsClaims.hasClaims]);
    
    function loadDocumentTypeSelectOptions() {
        const url = new URL(import.meta.env.VITE_CCP_API_CCP + "/DocumentTypeSelectOptions");
        url.searchParams.set("UserId", user.id);
        url.searchParams.set("ClaimId", userProfileLossDraftsClaim.claimId.toString());
        
        console.log("Fetch Document Types from " + url);
        
        get(url, accessToken)
        .then( response => {
            if (!response.ok) { throw response }
            return response.json() as Promise<SelectOption[]>
        })
        .then( searchResults => {
            let dt = new SelectOptions();
            dt.hasLoaded = true;
            dt.options = searchResults;
            dt.options.unshift(new SelectOption("", "-"));
            setDocumentTypeSelectOptions(dt);
        })
        .catch( error => {
            error.json().then((json) => {
                setDocumentTypeSelectOptions(new SelectOptions());
            })
        });
    };

    function showUploadDocumentModal() {
        setDocumentUploadModalShow(true);
    }

    function hideUploadDocumentModal() {
        setDocumentUploadModalShow(false);
    }

    if (!userProfileLossDraftsClaims.hasLoaded) {
      return (
        <Loading size='large' />
      );
    }

    if (!userProfileLossDraftsClaims.hasClaims) {
        return (
            <div>
                <Routes>
                    <Route path="*"
                        element={
                            <ClaimRegistration />
                        } 
                    />
                </Routes>
            </div>
        );
    }
    
    return (
        <div>
            {
                validClaimsPaths.includes(location.pathname.toLowerCase()) && 
                (
                    <Routes>
                        <Route path="*" 
                            element={
                                <ClaimDetails loadingDetails={loadingDetails}
                                    setLoadingDetails={setLoadingDetails} 
                                    isClaimActive={isClaimActive}
                                    setIsClaimActive={setIsClaimActive}
                                />
                            }
                        />
                    </Routes>
                )
            }
            <Routes>
                <Route path="/" element={
                    <Navigate to="tasks" replace={true} />
                } />
                <Route path="tasks"
                    element={
                        <ClaimTasks loadingDetails={loadingDetails}
                            isClaimActive={isClaimActive}
                            documentTypeSelectOptions={documentTypeSelectOptions}
                            documentUploadModalShow={documentUploadModalShow}
                            showUploadDocumentModal={showUploadDocumentModal}
                            hideUploadDocumentModal={hideUploadDocumentModal} 
                        />
                    }
                />
                <Route path="documents"
                    element={
                        <ClaimDocuments 
                            documentTypeSelectOptions={documentTypeSelectOptions}
                            documentUploadModalShow={documentUploadModalShow}
                            showUploadDocumentModal={showUploadDocumentModal}
                            hideUploadDocumentModal={hideUploadDocumentModal} 
                        />
                    }
                />
                <Route path="transactions"
                    element={<ClaimTransactions />}
                />
                <Route path="inspections"
                    element={<ClaimInspections />}
                />
                <Route path="contractors"
                    element={<ClaimContractors />}
                />
                <Route path="*"
                    element={<div>{t('App:lbl404')}</div>}
                /> 
            </Routes>
        </div>
    );
};

export default Claims;