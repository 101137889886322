export function formatAddressOneLine(line1:string[]) : string {
    return line1.filter(x => x > "").join(" ");
}

export function formatAddressMultiLine(line1:string[], line2:string[], line3:string[]) : string {
    return line1.filter(x => x > "").join(" ") 
        + (line2.filter(x => x > "").length > 0 ? "\n" + line2.filter(x => x > "").join(" ") : "")
        + (line3.filter(x => x > "").length > 0 ? "\n" + line3.filter(x => x > "").join(" ") : "");
}

export function formatCountry(country:string) : string {
    return country?.trim().toUpperCase() === "US" ? "" : country?.trim();
}

export function formatContact(name:string, address:string, email:string, phone:string) : string {
    return name
        + (address > "" ? "\n" + address : "")
        + (email > "" ? "\n" + email : "")
        + (phone > "" ? "\n" + phone : "");
}

export function formatCompanyContact(companyName, contactName) : string {
    return '' + 
        (companyName > '' ? companyName : '') +
        (contactName > '' ? (' (' + contactName + ')') : '')
    ;
}

export function formatDate(utcDateTime:string) : string {
    return new Intl.DateTimeFormat("en-US", { dateStyle:"medium", timeZone:"America/New_York" }).format(Date.parse(utcDateTime));
}

export function formatPhone(internationalCode, areaCode, phoneNumber, extension) : string {
    return '' + 
        (internationalCode > '' ? ('+' + internationalCode + ' ') : '') +
        (areaCode > '' ? ('(' + areaCode + ') ') : '') +
        (phoneNumber > '' 
            ? (
                phoneNumber.length === 7 
                ? (
                    phoneNumber.substring(0,3) + '-' + phoneNumber.substring(3,7)
                ) 
                : (
                    phoneNumber.length === 10 
                    ? (
                        '(' + phoneNumber.substring(0,3) + ') ' + phoneNumber.substring(3,6) + '-' + phoneNumber.substring(6,10)
                    )   
                    : phoneNumber
                )
            ) 
            : ''
        ) +
        (extension > '' ? (' ext. ' + extension) : '')
    ;
}